<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="12" :sm="12" :xl="8" :xxl="4">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入告警策略名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="12" :sm="12" :xl="8" :xxl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-card :bordered="false">
      <a-space style="margin-bottom: 16px;">
        <a-button icon="plus" type="primary" @click="$router.push({ name: 'AlertStrategyCreate' })">创建</a-button>
      </a-space>

      <div>
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="loading"
          :pagination="false"
          row-key="id"
          :row-selection="rowSelection"
          :scroll="{ x: scrollX }"
          @change="change"
          style="margin-bottom: 16px;"
        >
          <template slot="name" slot-scope="record">
            <a-tooltip placement="topLeft" :title="record.name">
              <a @click="$router.push({ name: 'AlertStrategyDetail', params: { id: record.id } })">{{ record.name }}</a>
            </a-tooltip>
          </template>
          <template slot="severities" slot-scope="record">
            <severity-tag v-for="severity in record" :key="severity" :severity="severity"></severity-tag>
          </template>
<!--          <template slot="statistic" slot-scope="record">-->
<!--            <a-tag color="purple" style="margin-right: 0;">触发规则: {{ record.trigger }}</a-tag>-->
<!--            <a-divider type="vertical"></a-divider>-->
<!--            <a-tag color="blue" style="margin-right: 0;">通知规则: {{ record.notification }}</a-tag>-->
<!--          </template>-->
          <template slot="active" slot-scope="text, record">
            <a-switch :checked="record.active" @change="checked => update(record.id, checked)"></a-switch>
          </template>
          <template slot="operation" slot-scope="record">
            <a @click="$refs.updateForm.show(record.id)" style="color: #faad14;">
              <a-space style="user-select: none;">
                <a-icon type="edit"></a-icon>
                <span>编辑</span>
              </a-space>
            </a>
            <a-divider type="vertical"></a-divider>
            <a-popconfirm ok-type="danger" title="是否确定删除？" @confirm="confirm(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: #f5222d;"></a-icon>
              <a style="color: #f5222d;">
                <a-space style="user-select: none;">
                  <a-icon type="delete"></a-icon>
                  <span>删除</span>
                </a-space>
              </a>
            </a-popconfirm>
          </template>
        </a-table>
        <update-form ref="updateForm" @ok="fetch"></update-form>

        <div style="height: 32px;">
          <span v-if="rowSelection.selectedRowKeys.length" style="line-height: 32px;">{{ `已选中 ${rowSelection.selectedRowKeys.length} 条` }}</span>
          <a-pagination
            v-model="form.page"
            :pageSize="form.page_size"
            :show-size-changer="true"
            :show-total="t => `共 ${t} 条`"
            :total="total"
            @change="page => { this.form.page = page; this.fetch() }"
            @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.page_size = pageSize; this.fetch() }"
            style="float: right;"
          ></a-pagination>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import {
  deleteAlertStrategy,
  getAlertStrategyList,
  updateAlertStrategy
} from '@/api/alert-strategy'
import SeverityTag from '@/components/tag/SeverityTag'
import UpdateForm from './modules/UpdateForm'

export default {
  name: 'AlertStrategyList',
  components: {
    SeverityTag,
    UpdateForm
  },
  data () {
    return {
      form: { page: 1, page_size: 10 },
      columns: [
        { title: '告警策略名称', width: 200, ellipsis: true, scopedSlots: { customRender: 'name' } },
        { dataIndex: 'severities', title: '严重性', width: 240, scopedSlots: { customRender: 'severities' } },
        { dataIndex: 'created_at', title: '创建时间', width: 200 },
        // { dataIndex: 'statistic', title: '统计', width: 240, scopedSlots: { customRender: 'statistic' } },
        {
          dataIndex: 'active',
          title: '状态',
          width: 100,
          fixed: 'right',
          scopedSlots: { customRender: 'active' },
          filters: [
            { text: '启用', value: 'true' },
            { text: '停用', value: 'false' }
          ],
          filterMultiple: false
        },
        { title: '操作', width: 160, fixed: 'right', scopedSlots: { customRender: 'operation' } }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: selectedRowKeys => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => { sum += column.width })
      return sum
    },
    disabled () {
      return this.rowSelection.selectedRowKeys.length === 0
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getAlertStrategyList(this.form).then(res => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    },
    search () {
      this.form.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    },
    change (_, filters) {
      this.form.active = filters.active[0]
      this.fetch()
    },
    update (id, checked) {
      updateAlertStrategy(id, { active: checked }).then(res => {
        this.$message.success(res.message)
        this.fetch()
      })
    },
    confirm (id) {
      deleteAlertStrategy(id).then(res => {
        this.$message.success(res.message)
        if (this.total - 1 <= (this.form.page - 1) * this.form.pageSize) {
          if (this.form.page > 1) this.form.page -= 1
        }
        this.fetch()
      })
    }
  }
}
</script>
