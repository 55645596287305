<template>
  <a-modal :confirm-loading="loading" title="编辑告警策略" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="策略名称" prop="name">
        <a-input v-model="form.name" :max-length="128" placeholder="请输入策略名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="严重性" prop="severities">
        <severity-select v-model="form.severities" :allow-clear="false" mode="multiple"></severity-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getAlertStrategy, updateAlertStrategy } from '@/api/alert-strategy'
import SeveritySelect from '@/components/select/SeveritySelect'

export default {
  name: 'UpdateForm',
  components: { SeveritySelect },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        name: '',
        severities: []
      },
      rules: {
        name: [{ message: '请输入策略名称', required: true, trigger: 'blur' }],
        severities: [{ message: '请选择严重性', required: true, trigger: 'change' }]
      },
      id: ''
    }
  },
  methods: {
    show (id) {
      this.id = id
      getAlertStrategy(id).then(res => {
        const data = res.data
        this.form.name = data.name
        this.form.severities = data.severities
      }).finally(() => {
        this.visible = true
      })
    },
    reset () {
      this.form = {
        name: '',
        severities: []
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          updateAlertStrategy(this.id, this.form).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
