<template>
  <a-select
    :allow-clear="allowClear"
    :mode="mode"
    :filter-option="false"
    :options="options"
    placeholder="请选择严重性"
    :show-arrow="showArrow"
    :value="value"
    @change="v => $emit('input', v)"
  ></a-select>
</template>

<script>
export default {
  name: 'SeveritySelect',
  props: {
    allowClear: { type: Boolean, default: true },
    mode: { type: String, default: 'default' },
    showArrow: { type: Boolean, default: true },
    value: { type: [String, Array] }
  },
  data () {
    return {
      options: [
        { title: this.$t('severity.disaster'), value: 'disaster' },
        { title: this.$t('severity.high'), value: 'high' },
        { title: this.$t('severity.average'), value: 'average' },
        { title: this.$t('severity.warning'), value: 'warning' }
      ]
    }
  }
}
</script>
