<template>
  <a-tag :color="color">{{ $t(`severity.${severity}`) }}</a-tag>
</template>

<script>
import { severityColor } from '@/utils'

export default {
  name: 'SeverityTag',
  props: {
    severity: { type: String, default: 'warning' }
  },
  computed: {
    color () {
      return severityColor(this.severity)
    }
  }
}
</script>
